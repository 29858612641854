import { put, select } from "redux-saga/effects";
import i18n from '../../i18n';

import axios from "../axios-sipac";
import * as actions from "../actions";

import { ImputePart, Activity} from '../../types/Activity';
import { imputationsTotals, getError } from '../../lib/Utils';
import { AxiosResponse } from "axios";
import { OptionType, ResponseGenerator } from "../../types/Props";

export function* putActivitiesSaga(action: any) {
  yield put(actions.putActivitiesStart());
  let mes = action.part.month + 1;
  const url = `employee/imputations/${action.part.employee}/${mes}/${action.part.year}/${action.state}`;
  try {
    // validate
    const msg: string = yield validate(action.part, action.state, action.conceptsMap, action.forceSend);
    if (msg) {
      throw new Error(msg);
    }

    yield axios.put(url, action.part);
    yield put(
      actions.putActivitiesSuccess(action.state, action.state === 'GE' ? i18n.t('Guardado') : i18n.t('Enviado'))
    );
  } catch (error) {
    yield put(actions.putActivitiesFail(getError(error)));
  }
}

export function* fetchActivitiesSaga(action: any) {
  yield put(actions.fetchActivitiesStart(action.employee, action.month - 1, action.year));
  const url = `employee/imputations/${action.employee}/${action.month}/${action.year}`;

  try {
    const response: AxiosResponse<any> = yield axios.get(url);
    let vacationDays: number[] = yield visaDaysInMonth(action.employee, action.year, action.month);
    const fetchedActiv: ImputePart = yield convertInputations(
        response.data, action.employee, action.month - 1, action.year, vacationDays);

    const projectCods = fetchedActiv.activities.map(
      (activ) => activ.project
    );
    yield put(actions.fetchActivitiesSuccess(fetchedActiv, projectCods, vacationDays));
  } catch (error) {
    yield put(actions.fetchActivitiesFail(getError(error)));
  }
}

export function* putHolidaySaga(action: any) {
  yield put(actions.putActivitiesStart());

  try {
    let vacationDays = [];
    let checkHolidays: ResponseGenerator = yield f_validateHolidaysProject(action.part.activities[action.index].project);
    if (checkHolidays['return'] === 1) {
      vacationDays = yield visaDaysInMonth(action.part.employee, action.part.year, action.part.month + 1);
    }
    yield put(actions.putHolidaySuccess(action.index, vacationDays, checkHolidays['return'] === 1));
  } catch (error) {
    yield put(actions.putActivitiesFail(getError(error)));
  }
}

const convertInputations = async (data: any, employee: number, month: number, year: number, vacationDays: any) => {

  let ret;
  ret = new ImputePart();
  ret.employee = employee;
  ret.month = month;
  ret.year = year;

  for(var i=0; i<data.length; i++){

    let days = data[i]['dias'];

    // Empty activity, by default
    let activity = new Activity();
    activity.project = data[i]['proyecto'];
    activity.concept = data[i]['concepto'];
    let holidaysProject = await f_holidaysProject_(activity.project);
    if (holidaysProject['return'] === 1) {
      activity.holidays = true;
    }

    let hours = [];    
    let obs = []; 
    for(var j=0; j <  days; j++){
      var stDay = String(j+1).padStart(2, '0');
      hours[j] = !activity.holidays && vacationDays.includes(j) ? 0 : data[i]["dia" + stDay];
      let dayState = data[i]["rev" + stDay];
      if (dayState && '--'.localeCompare(dayState) !== 0) {
        activity.state = dayState;
      }
      obs[j] = data[i]["obs" + stDay];
    }
    activity.hours = hours;
    activity.obs = obs;
    ret.activities.push(activity); 
  }

  imputationsTotals(ret);

  ret.modifiable = ret.activities.find(
    (activ) => activ.state === 'GE' || activ.state === 'RG'
    ) != null

  return ret;
}


function* f_inProcessMonth (employee: number, year: number, month: number) {
  const url = `holidays/inProcessMonth/${employee}/${year}/${month}`;
  const response: AxiosResponse<any> = yield axios.get(url);
  return response.data;
}


function* f_visaMonth(employee: number, year: number, month: number) {
  const url = `holidays/visaMonth/${employee}/${year}/${month}`;
  const response: AxiosResponse<any> = yield axios.get(url);
  return response.data;
}

function* visaDaysInMonth(employee: number, year: number, month: number) {
  let days = [];
  const visaMonth: any[] = yield f_visaMonth(employee, year, month);
  if (visaMonth && visaMonth['length'] > 0) {
    for (var i = 0; i < visaMonth['length']; i++) {
      let aux = visaMonth[i];
      if (!aux) {
        continue;
      }
      let holidayDate = new Date(visaMonth[i]['DIA']);
      days.push(holidayDate.getUTCDate() - 1);
    } 
  }  
  const enjoyedMonth: any[] = yield f_enjoyedMonth(employee, year, month);
  if (enjoyedMonth && enjoyedMonth['length'] > 0) {
    for (var j = 0; j < enjoyedMonth['length']; j++) {
      let holidayDate = new Date(enjoyedMonth[j]['DIA']);
      days.push(holidayDate.getUTCDate() - 1);
    } 
  }  
  return days;
}  

function* f_enjoyedMonth(employee: number, year: number, month: number) {
  const url = `holidays/enjoyedMonth/${employee}/${year}/${month}`;
  const response: AxiosResponse<any> = yield axios.get(url);
  return response.data;
}

function* f_holidaysProject(idProyecto: number) {
  const url = `holidays/holidaysProject/${idProyecto}`;
  const response: AxiosResponse<any> = yield axios.get(url);
  return response.data;
}

const f_holidaysProject_ = async (idProyecto: number) => {
  const url = `holidays/holidaysProject/${idProyecto}`;
  const response: AxiosResponse<any> = await axios.get(url);
  return response.data;
}

function* f_validateHolidaysProject(cdProyecto: number) {
  const url = `holidays/validateHolidaysProject/${cdProyecto}`;
  const response: AxiosResponse<any> = yield axios.get(url);
  return response.data;
}

function* f_timecontrols(employee: number, year: number, month: number) {
  const url = `employee/timecontrols/${employee}/${month}/${year}`;
  const response: AxiosResponse<any> = yield axios.get(url);
  return response.data;
}

const dayTimeControlled = (timecontrols: any, year: number, month: number, day: number) => {
  const monthFormatted = ('00'+month).slice(-2);
  const dayFormatted = ('00'+day).slice(-2);
  const dayToFind = `${year}-${monthFormatted}-${dayFormatted}`;
  for ( const tc of timecontrols ) {
    const day = tc['DIA'];
    const minutes = tc['TOTAL_MINUTES'];
    if (day && day.startsWith(dayToFind) && minutes > 0) {
      return true;
    }
  }    
  return false;
}

function* f_employeeType(employee: number) {
  const url = `employee/${employee}/type`;
  const response: AxiosResponse<any> = yield axios.get(url);
  return response.data['return'];
}

function* f_projectCategory(project: number) {
  const url = `project/${project}/category`;
  const response: AxiosResponse<any> = yield axios.get(url);
  return response.data['return'];
}

const dayproductiveHours = (part: ImputePart, projectCategories: string[], day: number, conceptsMap: any) => {
  let total = 0;
  for (var i = 0; i < part.activities.length; i++) {
    let clientAdjust = conceptsMap[part.activities[i].project].some(
      (item: OptionType) => parseInt(item.value) === part.activities[i].concept && item.label.toUpperCase() === 'AJUSTE CLIENTE')
    if (projectCategories[i] === 'PR' && !clientAdjust) {
      total += part.activities[i].hours[day];
    }
  }
  return total
} 

function* validate(part: ImputePart, state: string, conceptsMap:any, forceSend: boolean) {
  if (part.activities.find(
    (activ) => !activ.project || !activ.concept)) {
     return  i18n.t('No se puede dejar vacio ni el proyecto ni el concepto.')
  }

  var alreadySeen: any = {};
  for (const activ of part.activities) {
      for (var i = 0; i < activ.hours.length; i++) {
        if (!activ.hours[i] && activ.hours[i] !== 0) {
          return i18n.t( 'No se puede dejar ningún campo día vacío: ', {dia: i})
        } else if (activ.hours[i] > 24) {
          return i18n.t( 'No se pueden asignar más de 24 horas, para el día: ', {dia: i})
        }
      }    
      let key = activ.project + '_' + activ.concept;
      if (alreadySeen[key]) {
        return i18n.t( 'No puede haber dos conceptos iguales para el mismo proyecto')
      }
      alreadySeen[key] = true;
  };

  // Validate holidays only on sending part
  if (state === 'GE') {
    return;
  }

  try {
    // Validate timecontrols
    const enterprise: number = yield select( (state) => state.auth.enterprise);
    const urlEnterprise: number = yield select( (state) => state.auth.urlEnterprise);
    let timecontrols: any[] = [];
    let empType: number  = yield f_employeeType(part.employee);
    if (empType === 1 && !forceSend) {
        let projectCategories: string[]  = [];
        for (const activ of part.activities) {
          let projectCategory: string  = yield f_projectCategory(activ.project);
          projectCategories.push(projectCategory);
        }
        timecontrols = yield f_timecontrols(part.employee, part.year, part.month + 1);
        const currentDay = new Date().getDate();
        for (let i = 0; i < currentDay - 1; i++) {
            let hours = dayproductiveHours(part, projectCategories, i, conceptsMap);
            var timecontrolCheck = dayTimeControlled(timecontrols, part.year, part.month + 1, i + 1);
            if (hours > 0 && !timecontrolCheck) {
              return i18n.t('El día tiene horas imputadas, pero', {dia: i + 1, url: urlEnterprise})
            }
            else if (hours == 0 && timecontrolCheck)
            {
              return i18n.t('Para el día tiene fichajes en', {dia: i + 1, url: urlEnterprise})
            }
        }
    }

    // Validate holidays
    const enjoyedMonth: any[] = yield f_enjoyedMonth(part.employee, part.year, part.month + 1);
    const inProcessMonth: any[] = yield f_inProcessMonth(part.employee, part.year, part.month + 1);
    const visaMonth: any[] = yield f_visaMonth(part.employee, part.year, part.month + 1);

    if (inProcessMonth && inProcessMonth['length'] > 0) {
      return  i18n.t('Aún existe solicitud de vacaciones en trámite.')
    } else if (visaMonth && visaMonth['length'] === 0 && enjoyedMonth && enjoyedMonth['length'] === 0) {
      let vac = false;
      for (var j = 0; j < part.activities.length; j++) {
        let activ = part.activities[j];
        let holidaysProject: ResponseGenerator = yield f_holidaysProject(activ.project);
        if (holidaysProject['return'] === 1) {
          vac = true;
        }
      } 
      if (vac) {
        return  i18n.t('No puede enviar un part de vacaciones si no ha enviado una solicitud previa a su gerente.')
      }
    }

    if (visaMonth && visaMonth['length'] > 0) {
      let vac = false;
      for (var k = 0; k < part.activities.length; k++) {
        let activ = part.activities[k];
        let holidaysProject: ResponseGenerator = yield f_holidaysProject(activ.project);
        if (holidaysProject['return'] === 1) {
          vac = true;
        }
      } 
      if (!vac) {
        return  i18n.t('Tiene vacaciones visadas para este mes. Por favor impute al proyecto vacaciones antes de enviar el parte.')
      }
    }

  } catch (e: any) {
    return e.message;
  }  
}
